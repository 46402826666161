import * as React from 'react'

import { Liquor, LocalBar, Newspaper, RestaurantMenu, WineBar } from '@mui/icons-material'
import { Box, Button, Divider, Grid, alpha } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import BookButton from '../../components/book_button'
import CursiveTypography from '../../components/cursive_typography'
import NavBar from '../../components/nav_bar'
import { Parallax, ParallaxGroup, ParallaxLayer } from '../../components/parallax'
import SeoHeaders from '../../components/seo_headers'
import SocialButtons from '../../components/social_buttons'
import LandingFooter from '../../sections/landing/footer'

type ResponsiveButtonProps = {
  href: string
  icon: React.ReactNode
  title: React.ReactNode
  subtitle: React.ReactNode
}

const ResponsiveButton = ({
  href,
  icon,
  title,
  subtitle,
}: ResponsiveButtonProps) => (
  <Grid
    item
    xs={12}
    md={6}
  >
    <Button
      variant='outlined'
      size='large'
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      sx={{
        py: 2,
        color: 'background.default',
        backgroundColor: alpha('#000', 0.4),
        border: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 2,
        '&:hover': {
          border: 2,
          borderColor: 'primary.main',
          backgroundColor: alpha('#52639a', 0.2),
        },
      }}
      fullWidth
    >
      {icon}
      <span style={{ display: 'flex', flexDirection: 'column' }}>
        <strong style={{ lineHeight: 1.1 }}>{title}</strong>
        <small style={{ lineHeight: 1.4 }}>{subtitle}</small>
      </span>
    </Button>
  </Grid>
)

const EsmeraldaMenuPage = () => (
  <React.Fragment>
    <SeoHeaders
      title='Cartas - Esmeralda'
      description='Conozca la carta de nuestro restaurant Esmeralda.'
    />
    <NavBar />
    <SocialButtons />
    <BookButton />
    <Parallax>
      <ParallaxGroup
        sx={(theme) => ({
          minHeight: '740px',
          [theme.breakpoints.up('md')]: {
            minHeight: '500px',
          },
        })}
      >
        <ParallaxLayer depth={-1}>
          <StaticImage
            src='../../images/partnerships/salon-prat.jpeg'
            alt='Salón Prat en Restaurant Esmeralda'
            layout='fullWidth'
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
            quality={90}
          />
        </ParallaxLayer>
        <ParallaxLayer
          sx={{
            background: 'linear-gradient(to top, rgba(53, 53, 53, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)',
            color: 'background.default',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              maxWidth: 'md',
              mx: 'auto',
              p: 3,
            }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <CursiveTypography
                  variant='h1'
                  textAlign='center'
                >
                  Carta Esmeralda
                </CursiveTypography>
              </Grid>
              <ResponsiveButton
                href='https://drive.google.com/file/d/1y2f4xz9w0mXNIA6ODddKxAba2BFvX2PQ/view'
                icon={<RestaurantMenu />}
                title='Comida'
                subtitle='Food'
              />
              <ResponsiveButton
                href='https://drive.google.com/file/d/1b9DOc0q07VKm9PprajZWNMkBxNt34gnu/view'
                icon={<LocalBar />}
                title='Bebestibles'
                subtitle='Drinks'
              />
              <ResponsiveButton
                href='https://drive.google.com/file/d/1JpT6lS0hP1SoUliPSsQEGhX4qmaX0QRy/view'
                icon={<Liquor />}
                title='Selección del Sommelier'
                subtitle="Sommelier's Choice"
              />
              <ResponsiveButton
                href='https://drive.google.com/file/d/1Pb65jWTxmGUshZ7roEw5MinvwgWO3Y2l/view'
                icon={<WineBar />}
                title='Vinos'
                subtitle='Wines'
              />
              <Grid
                item
                xs={12}
              >
                <Divider sx={{ borderColor: 'common.white' }} />
              </Grid>
              <ResponsiveButton
                href='https://drive.google.com/file/d/1J4xlzwjr05TgyttdWMDmqrFid_s64Q_N/view'
                icon={<RestaurantMenu />}
                title='Cardápio 🇧🇷 🇵🇹'
                subtitle={'Menu'}
              />
              <ResponsiveButton
                href='https://drive.google.com/drive/folders/1Q6FDLt2ZxOGgbNbvdH1UY_YLZ5KP33v5'
                icon={<Newspaper />}
                title='Novedades 🐠'
                subtitle={'News'}
              />
            </Grid>
          </Box>
        </ParallaxLayer>
      </ParallaxGroup>
      <LandingFooter />
    </Parallax>
  </React.Fragment>
)

export default EsmeraldaMenuPage
